import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function SearchFormGuide() {
  return (
    <Layout
      keywords="Zendesk ticket form, integrate search"
      title="Set up intelligent search on your Zendesk ticket form submission page"
      description="Documentation on how to integrate IrisAgent search with your Zendesk ticket form submission page"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/search-form-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Documentation for IrisAgent Search on Zendesk Ticket Form</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Search on Zendesk Ticket Form</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin:"10%" }}>

                    <h1>Integrating IrisAgent Search with your Zendesk Ticket Form</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's AI can power search and ticket deflection on your Zendesk Ticket Form to deflect customer queries and reduce the number of tickets handled by support agents.
                    <br/>
                    <br/>
                    <h2>
                    Installation Guide
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Set up the ticket form (if you already have a ticket form set up, you can go to the next step). Please follow <a href="https://support.zendesk.com/hc/en-us/articles/4408836460698-Managing-your-ticket-forms" target="_blank">this link</a> to setup zendesk ticket forms.
                    </li>
                    <li>
                    Navigate to the <b>Customize Design</b> tab in your Zendesk Help Center. This can be found under Zendesk Guide > Guide Admin > Customize Design.
                    </li>
                    <li>
                    Edit your theme. You can either add a new theme by selecting <b>Add theme</b> and making a copy of your Live theme by selecting the three dots > Copy.
If you would like to edit your existing theme directly, click on <b>Customize</b> under your Live theme.
<br/><img
                src="/img/zd-help-theme.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>

                    </li>
                    <li>
                    In order to replace the default Zendesk search bar with the IrisAgent search bar, you will need to edit the code. After clicking on the <b>Customize</b> button in the previous step, Click <b>Edit Code</b> in the bottom right corner:
                    <br/><img
                src="/img/zd-help-edit-code.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              /><br/>

                    </li>
                    <li>
                    Navigate to the <b>New Request Page</b>. Once in the editor, on the left hand side, click on the new request page (new_request_page.hbs) file.
                    </li>
                    <li>
                    Within <b>new_request_page.hbs</b> file, add in the following lines underneath your request_form:
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`

<script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
    <script src="https://benalman.com/code/projects/jquery-throttle-debounce/jquery.ba-throttle-debounce.js"></script>

    <div id="irisagent-zendesk-ticket-form-search-bar"></div>

  	<iframe src="https://app.irisagent.com/zendesk/zendesk-ticket-form-searchbar/getZendeskTicketFormSearchBar?businessId=REPLACE_ME_BUSINESS_ID" id="irisagent-ticket-form-search-iframe" frameBorder="0" style="width: 45%; min-width: 45%;">		</iframe>
	<script src="https://app.irisagent.com/public/zendesk_ticket_form_searchbar_listeners.js"></script>
  `}
</SyntaxHighlighter>

                    </li>
                    <li>
                    Note: Insert your account business ID in the above code for <b>REPLACE_ME_BUSINESS_ID</b>. This is a secret that will be shared to you by IrisAgent.
                    </li>
                    <li>
                    Now that the IrisAgent search bar is going to be displayed in the home page, the default Zendesk search bar can be deleted from the code.
To make the deletion, remove the entire <code>{`<div class="search-container">`}</code> and all the code inside the div.
                    </li>
                    <li>
                    Also within new_request_page.hbs, add a <b>support_container</b> div and a <b>new-request-div</b> class around the form and the IrisAgent search bar.
                    </li>
                    <li>
                    The result of the above steps will look as follows:
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`
  <div class="container-divider"></div>
<div class="container">
  <nav class="sub-nav">
    {{breadcrumbs}}
  </nav>
  <div class="support-container">
    <div class="new-request-div">
        <h1>
            {{t 'submit_a_request'}}
            <span class="follow-up-hint">
            {{follow_up}}
            </span>
        </h1>
        <div id="main-content" class="form">
            {{request_form wysiwyg=true}}
        </div>
    </div> <!-- new-request-div closing tag -->

    <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
    <script src="https://benalman.com/code/projects/jquery-throttle-debounce/jquery.ba-throttle-debounce.js"></script>

    <div id="irisagent-zendesk-ticket-form-search-bar"></div>

  	<iframe src="https://app.irisagent.com/zendesk/zendesk-ticket-form-searchbar/getZendeskTicketFormSearchBar?businessId=REPLACE_ME_BUSINESS_ID" id="irisagent-ticket-form-search-iframe" frameBorder="0" style="width: 45%; min-width: 45%;">		</iframe>
		<script src="https://app.irisagent.com/public/zendesk_ticket_form_searchbar_listeners.js"></script>
  </div> <!-- support-container div closing tag -->
</div>`}
  </SyntaxHighlighter>
                    </li>
                    <li>
                    Head to your <b>style.css</b> file and add in CSS for the support container under the <b>.container</b> CSS:
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`
  .support-container {
  display: flex;
}`}
  </SyntaxHighlighter>
                    </li>
                    <li>
                    Also in <b>style.css</b>, under the “/***** Request *****/” comment, add in CSS for the new-request-form:
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`.new-request-div {
  position: relative;
  max-width: 55%;
  width: 55%;
  padding-bottom: 50px;
  margin-right: 5%;
  flex: 1.5;
}`}
  </SyntaxHighlighter>
                    </li>
                    <li>
                    Save your work. Click Save.
                    </li>
                    <li>
                    [optional] You can click on the preview button on the left side of the editor to see how the layout looks before deploying it.
                    </li>
                    <li>
                    If you are using a Live theme, you can choose to “Publish” your work.
                    </li>
                    <li>
                    That’s it, done! You should be seeing the IrisAgent search bar on your help center home page.
                    </li>
                    </ol>





                    </div>
                    </div>
                    </section>

</Layout>
  )
}
